var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"bacsic-info-men"},on:{"click":_vm.blur}},[_c('AppContainer',{attrs:{"useBackground":true}},[_c('div',{staticClass:"tutorial-basic-info f-maru"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"tutorial-notification__div card"},[(_vm.auth.login_type != 'LINE')?_c('div',{staticClass:"text-left",on:{"click":function($event){return _vm.$emit('back-tutorial')}}}):_vm._e(),_c('div',{staticClass:"process-bar-info"},[_c('process-bar-profile',{attrs:{"processStep":_vm.processStep}})],1),_c('p',{staticClass:"text-address f-w6"},[_vm._v(" プロフィールを登録しましょう ")]),_c('ValidationObserver',{ref:"observer",attrs:{"tag":"form"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{staticClass:"form",on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('ValidationProvider',[_c('div',{staticClass:"tutorial-welcome"},[_c('div',{staticClass:"\n                    text-left\n                    justify-content-center\n                    position-relative\n                    option\n                  "},[_c('div',{staticClass:"content--checkbox f-maru"},[_c('div',{staticClass:"d-flex position-relative align-items-center top age-acc"},[_c('div',{staticClass:"checkbox d-flex justify-content-center align-items-center",class:_vm.checkedAge ? 'active' : '',attrs:{"id":"checkbox"},on:{"click":function($event){_vm.checkedAge = !_vm.checkedAge}}},[(_vm.checkedAge)?_c('b-icon',{attrs:{"icon":"check"}}):_vm._e()],1),_c('span',{staticClass:"text"},[_c('span',{staticClass:"text--blue"},[_vm._v("20歳以上")]),_vm._v(" です。 ")])]),_c('div',{staticClass:"d-flex position-relative align-items-center"},[_c('div',{staticClass:"checkbox d-flex justify-content-center align-items-center checkbox-law",class:_vm.checkedLaw ? 'active' : '',attrs:{"id":"checkbox"},on:{"click":function($event){_vm.checkedLaw = !_vm.checkedLaw}}},[(_vm.checkedLaw)?_c('b-icon',{attrs:{"icon":"check"}}):_vm._e()],1),_c('span',{staticClass:"text text--bottom"},[_c('span',{staticClass:"text--blue text-law"},[_c('span',{staticClass:"underline",on:{"click":function($event){return _vm.showLaw()}}},[_vm._v("利用規約")]),_vm._v("、"),_c('span',{staticClass:"underline",on:{"click":function($event){return _vm.nextPolicy()}}},[_vm._v("プライバシーポリシー")])]),_c('span',{staticClass:"text-black"},[_vm._v("に同意する")])])])])])])]),_c('ValidationProvider',{attrs:{"name":"ニックネーム","rules":"required|min:1|max:8"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"\n                    text-left\n                    justify-content-center\n                    position-relative\n                    option\n                  "},[_c('p',{staticClass:"label f-maru"},[_c('span',[_vm._v("ニックネーム")]),_c('span',{staticClass:"requied-cl"},[_vm._v(" ※必須")])]),_c('div',{staticClass:"input-option"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.nickname),expression:"nickname"}],staticClass:"w-100",attrs:{"type":"text"},domProps:{"value":(_vm.nickname)},on:{"blur":function($event){return _vm.handleBlurNickname()},"keyup":_vm.changeNickName,"input":function($event){if($event.target.composing){ return; }_vm.nickname=$event.target.value}}})]),_c('span',{staticClass:"error text-left"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)}),_c('div',{staticClass:"text-left justify-content-center position-relative option"},_vm._l((_vm.select),function(select,index){return _c('div',{key:index,staticClass:"position-relative point",class:select.class},[_c('p',{staticClass:"label f-maru",class:index == 1 ? 'label--bottom' : ''},[_c('span',[_vm._v(_vm._s(select.name))]),_c('span',{staticClass:"requied-cl"},[_vm._v(" ※必須")])]),_c('div',{staticClass:"focus-div",attrs:{"id":index == 0 ? 'best-score' : 'avg-score'}},[_c('div',{staticClass:"\n                    position-relative\n                    d-flex\n                    align-items-center\n                    button-option\n                  ",class:select.isShow ? 'active' : '',on:{"click":function($event){return _vm.showOptionPoint(index, select.name)}}},[_c('div',{staticClass:"w-100 input d-flex align-items-center",attrs:{"type":""}},[_vm._v(" "+_vm._s(select.value)+" ")])]),_c('transition',{attrs:{"name":"slide"}},[(select.isShow)?_c('div',{staticClass:"options"},_vm._l((select.options),function(option,indexOption){return _c('p',{key:indexOption,class:select.name,on:{"click":function($event){return _vm.selectOptionPoint(
                              index,
                              select.options[indexOption]
                            )}}},[_vm._v(" "+_vm._s(select.options[indexOption])+" ")])}),0):_vm._e()])],1),_c('span',{staticClass:"error"},[_vm._v(_vm._s(select.error))])])}),0),_c('div',{staticClass:"edit text-left justify-content-center option row box-date"},_vm._l((_vm.text),function(text,index){return _c('div',{key:index,staticClass:"position-relative col-4 date-padding",class:{
                    disabled:
                      (index == 1 && !_vm.showMonth) || (index == 2 && !_vm.showDay)
                  }},[_c('p',{staticClass:"text-year f-maru"},[_vm._v(_vm._s(text.name))]),_c('ValidationProvider',{attrs:{"name":text.name,"rules":"required"}},[_c('div',{staticClass:"focus-div",attrs:{"id":text.name}},[_c('div',{staticClass:"\n                          position-relative\n                          d-flex\n                          align-items-center\n                          button-option\n                        ",class:text.isShow ? 'active' : '',on:{"click":function($event){return _vm.showOption(index, text.name)}}},[_c('div',{staticClass:"d-flex align-items-center w-100 input"},[_vm._v(" "+_vm._s(text.value)+" ")])]),_c('transition',{attrs:{"name":"slide"}},[(text.isShow)?_c('div',{staticClass:"options"},_vm._l((text.options),function(option,indexOption){return _c('p',{key:indexOption,class:text.name,on:{"click":function($event){return _vm.selectOption(
                                index,
                                text.options[indexOption],
                                indexOption
                              )}}},[_vm._v(" "+_vm._s(text.options[indexOption])+" ")])}),0):_vm._e()])],1),_c('span',{staticClass:"error text-left"},[_vm._v(_vm._s(text.error))])])],1)}),0),_c('div',{staticClass:"only-show-profile-text text-left justify-content-center option text-btn suggest-year"},[_c('img',{attrs:{"src":require("@/assets/image/icon_profile/profile-note-icon.png"),"alt":""}}),_c('p',[_vm._v(" 年齢のみ表示されます。 ")])]),_c('div',{staticClass:"basic-info-acc f-w6"},[_c('p',[_vm._v("基本情報")])]),_c('ValidationProvider',{attrs:{"name":"電話番号","rules":"required|regex:^\\d{3}-\\d{4}-\\d{4}$"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                              var errors = ref.errors;
return [_c('div',{staticClass:"\n                    text-left\n                    justify-content-center\n                    position-relative\n                    option\n                  "},[_c('p',{staticClass:"label f-maru"},[_c('span',[_vm._v("電話番号")]),_c('span',{staticClass:"requied-cl"},[_vm._v(" ※必須")])]),_c('div',{staticClass:"input-option"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.phone),expression:"phone"}],staticClass:"w-100 input-phone-number",attrs:{"type":"tel"},domProps:{"value":(_vm.phone)},on:{"keydown":function($event){return _vm.checkPhone()},"blur":function($event){return _vm.handleBlur()},"input":function($event){if($event.target.composing){ return; }_vm.phone=$event.target.value}}})]),_c('div',{staticClass:"text-left m-auto text-btn"},[_c('span',{staticClass:"warning"},[_vm._v(_vm._s(errors[0]))])]),_c('div',{staticClass:"only-show-profile-text"},[_c('img',{attrs:{"src":require("@/assets/image/icon_profile/profile-note-icon.png"),"alt":""}}),_c('p',[_vm._v(" プロフィールには公開されません。 ")])]),_c('div',{staticClass:"only-show-profile-text"},[_c('img',{attrs:{"src":require("@/assets/image/icon_profile/profile-note-icon.png"),"alt":""}}),_c('p',[_vm._v(" 数字は半角で、ハイフン（-）は無しで記載してください。 ")])])])]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"郵便番号","rules":"required|regex:^\\d{3}-\\d{4}$"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                              var errors = ref.errors;
return [_c('div',{staticClass:"\n                    text-left\n                    justify-content-center\n                    position-relative\n                    option\n                    num-address\n                  "},[_c('p',{staticClass:"label f-maru lb-address"},[_c('span',[_vm._v("郵便番号")]),_c('span',{staticClass:"requied-cl"},[_vm._v(" ※必須")])]),_c('div',{staticClass:"\n                      d-flex\n                      text-left\n                      justify-content-center\n                      position-relative\n                      option\n                    "},[_c('div',{staticClass:"input-option"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.postCode),expression:"postCode"}],staticClass:"post-code",attrs:{"type":"text"},domProps:{"value":(_vm.postCode)},on:{"keydown":function($event){return _vm.checkPostCode()},"blur":function($event){return _vm.handleBlur()},"input":function($event){if($event.target.composing){ return; }_vm.postCode=$event.target.value}}})]),_c('div',{staticClass:"\n                        mt-0\n                        button-post-code\n                        f-maru\n                        d-flex\n                        align-items-center\n                        justify-content-center\n                      ",on:{"click":_vm.fillPrefecture}},[_vm._v(" 住所検索 ")])]),_c('p',{staticClass:"error text-left"},[_vm._v(_vm._s(errors[0]))]),_c('div',{staticClass:"only-show-profile-text"},[_c('img',{attrs:{"src":require("@/assets/image/icon_profile/profile-note-icon.png"),"alt":""}}),_c('p',[_vm._v("プロフィールには都道府県しか表示されません")])])])]}}],null,true)}),_c('div',{staticClass:"text-left position-relative option box-address"},[_c('div',{staticClass:"province"},[_c('ValidationProvider',{attrs:{"name":"都道府県","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                              var errors = ref.errors;
return [_c('div',{staticClass:"\n                    text-left\n                    justify-content-center\n                    position-relative\n                    option\n                  "},[_c('div',[_c('p',{staticClass:"label f-maru"},[_c('span',[_vm._v(" 都道府県 ")]),_c('span',{staticClass:"requied-cl"},[_vm._v(" ※必須")])]),_c('div',{staticClass:"input-option"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.prefecture),expression:"prefecture"}],attrs:{"type":"text","readonly":""},domProps:{"value":(_vm.prefecture)},on:{"input":function($event){if($event.target.composing){ return; }_vm.prefecture=$event.target.value}}})]),_c('span',{staticClass:"error text-left"},[_vm._v(_vm._s(errors[0]))])])])]}}],null,true)})],1),_c('div',{staticClass:"district"},[_c('ValidationProvider',{attrs:{"name":"市区町村","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                              var errors = ref.errors;
return [_c('div',{staticClass:"\n                    text-left\n                    justify-content-center\n                    position-relative\n                    option\n                  "},[_c('div',[_c('p',{staticClass:"label f-maru"},[_c('span',[_vm._v("市区町村")]),_c('span',{staticClass:"requied-cl"},[_vm._v(" ※必須")])]),_c('div',{staticClass:"input-option"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.residence),expression:"residence"}],attrs:{"type":"text","readonly":""},domProps:{"value":(_vm.residence)},on:{"input":function($event){if($event.target.composing){ return; }_vm.residence=$event.target.value}}})]),_c('span',{staticClass:"error text-left"},[_vm._v(_vm._s(errors[0]))])])])]}}],null,true)})],1)]),_c('ValidationProvider',{attrs:{"name":"町域","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                              var errors = ref.errors;
return [_c('div',{staticClass:"\n                    text-left\n                    justify-content-center\n                    position-relative\n                    option\n                  ",attrs:{"hidden":""}},[_c('div',[_c('p',{staticClass:"label f-maru"},[_c('span',[_vm._v("町域")]),_c('span',{staticClass:"requied-cl"},[_vm._v(" ※必須")])]),_c('div',{staticClass:"input-option"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.area),expression:"area"}],attrs:{"type":"text","readonly":""},domProps:{"value":(_vm.area)},on:{"input":function($event){if($event.target.composing){ return; }_vm.area=$event.target.value}}})]),_c('span',{staticClass:"error text-left"},[_vm._v(_vm._s(errors[0]))])])])]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"丁目","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                              var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.areaDetail),expression:"areaDetail"}],attrs:{"type":"text","hidden":""},domProps:{"value":(_vm.areaDetail)},on:{"input":function($event){if($event.target.composing){ return; }_vm.areaDetail=$event.target.value}}}),_c('div',{staticClass:"\n                    text-left\n                    justify-content-center\n                    position-relative\n                    option\n                  "},[_c('div',[_c('div',{staticClass:"d-flex label align-items-center"},[_c('p',{staticClass:"f-maru chome-lb"},[_c('span',[_vm._v("丁目")]),_c('span',{staticClass:"requied-cl"},[_vm._v(" ※必須")])])]),_c('div',{staticClass:"focus-div",attrs:{"id":"residence"}},[_c('div',{staticClass:"\n                          position-relative\n                          d-flex\n                          align-items-center\n                          button-option\n                        ",class:_vm.isShowResidences ? 'active' : '',on:{"click":_vm.showOptionResidences}},[_c('div',{staticClass:"w-100 input d-flex align-items-center"},[_vm._v(" "+_vm._s(_vm.areaDetail)+" ")])]),_c('transition',{attrs:{"name":"slide"}},[(_vm.isShowResidences && _vm.listArea.length)?_c('div',{staticClass:"options"},_vm._l((_vm.listArea),function(option,indexOption){return _c('p',{key:indexOption,staticClass:"option-residence",on:{"click":function($event){return _vm.selectOptionArea(option.name)}}},[_vm._v(" "+_vm._s(option.name)+" ")])}),0):_vm._e()])],1),_c('span',{staticClass:"error text-left"},[_vm._v(_vm._s(errors[0]))])])])]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"番地","rules":"required|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                              var errors = ref.errors;
return [_c('div',{staticClass:"\n                    text-left\n                    justify-content-center\n                    position-relative\n                    option\n                  "},[_c('p',{staticClass:"label f-maru"},[_c('span',[_vm._v("番地")]),_c('span',{staticClass:"requied-cl"},[_vm._v(" ※必須")])]),_c('div',{staticClass:"input-option"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.street),expression:"street"}],attrs:{"type":"text","name":"","id":"","max":"255"},domProps:{"value":(_vm.street)},on:{"input":function($event){if($event.target.composing){ return; }_vm.street=$event.target.value}}})]),_c('span',{staticClass:"error text-left"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"マンション、アパート名","rules":"max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                              var errors = ref.errors;
return [_c('div',{staticClass:"\n                    text-left\n                    justify-content-center\n                    position-relative\n                    option\n                  "},[_c('p',{staticClass:"label f-maru other-address"},[_vm._v(" マンション、アパート名 ")]),_c('div',{staticClass:"input-option"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.house),expression:"house"}],attrs:{"type":"text","name":"","id":"","max":"255"},domProps:{"value":(_vm.house)},on:{"input":function($event){if($event.target.composing){ return; }_vm.house=$event.target.value}}})]),_c('span',{staticClass:"error text-left"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)}),_c('div',{staticClass:"d-flex justify-content-center btn-button"},[_c('button',{staticClass:"w-100",attrs:{"type":"submit","disabled":invalid}},[_c('div',{staticClass:"\n                      position-relative\n                      d-flex\n                      align-items-center\n                      button-back\n                    "},[_c('div',{staticClass:"text-center posision-absolute w-100 text-center"},[_vm._v(" 次へ ")]),_c('img',{staticClass:"icon",attrs:{"src":require("@/assets/image/icon-next.svg"),"alt":""}})])])])],1)]}}])})],1)])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }